// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
// import Home from './Pages/Home';
import ProductForm from './Components/ProductForm';
import CustomerTable from './Components/CustomerTable';
import TicketForm from './Components/TicketForm';
import AddProduct from './Components/AddProduct';
import PrivateRoute from './Components/PrivateRoute'; // Protect routes
import TicketTable from './Components/TicketTable';
import Login from './Components/Login';
function App() {
  return (
    <Router>
      <div>

      <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
        <div className='container'>
<a className="navbar-brand" href="https://www.tjdstore.in"><img className="logoimg" alt="Logo" width={150} src="https://www.jddigital.in/wp-content/uploads/2024/02/TJD-Digital-Logo-copy.png" /></a>
        
        <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ml-auto">
               <li className="nav-item">
                <Link className="nav-link" to="/">Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/ProductForm">Create Order</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/CustomerTable">Customers</Link>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link" to="/TicketForm">TicketForm</Link>
              </li> */}
              <li className="nav-item">
                <Link className="nav-link" to="/AddProduct">AddProduct</Link>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link" to="/TicketTable">Tickets</Link>
              </li> */}
            </ul>
        </div>
        </div>
    </nav>
      
        <Routes>
        <Route path="/login" element={<Login />} />
          <Route path="/ProductForm" element={<PrivateRoute><ProductForm /></PrivateRoute>} />
          <Route path="/CustomerTable" element={<PrivateRoute><CustomerTable /></PrivateRoute>} />
          <Route path="/TicketForm" element={<PrivateRoute><TicketForm /></PrivateRoute>} />
          <Route path="/AddProduct" element={<PrivateRoute><AddProduct /></PrivateRoute>} />       
          <Route path="/TicketTable" element={<PrivateRoute><TicketTable /></PrivateRoute>} />                 

        </Routes>

        
      </div>
    </Router>

  );
}

export default App;
