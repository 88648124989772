import React from 'react';
import { useNavigate } from 'react-router-dom';
import './style/style.css';

const LogoutButton = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const response = await fetch('https://tjdstore.in/api/logout.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const result = await response.json();

      console.log('Logout response:', result); // Debugging output

      if (result.success) {
        // Clear local authentication state
        localStorage.removeItem('isLoggedIn'); // or sessionStorage if used
        navigate('/login');
      } else {
        console.error('Logout failed:', result.message);
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <div className="midbox">
    <button className="btn btn-danger"  onClick={handleLogout}>
      Logout
    </button>
    </div>
  );
};

export default LogoutButton;
