import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  // Check if user is logged in (e.g., checking localStorage)
  const isLoggedIn = localStorage.getItem('isLoggedIn');

  // If not logged in, redirect to login page
  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  // If logged in, render the requested page
  return children;
};

export default PrivateRoute;
