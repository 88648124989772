import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CustomerTable = () => {
    const [customers, setCustomers] = useState([]);

    useEffect(() => {
        axios.get('https://tjdstore.in/api/get_customer.php')
            .then(response => setCustomers(response.data))
            .catch(error => console.error('Error fetching customers:', error));
    }, []);

    return (
        <div className="container topic">
            <div className="row">
                <div className="col-md-10">
                    <div className="mt-5">

            <h2 className="center">Customer Details</h2>
            <table className='table custumt'>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Mobile</th>
                        <th>Product Name1</th>
                        <th>Size1</th>
                        <th>Cost1</th>
                        <th>Product Name2</th>
                        <th>Size2</th>
                        <th>Cost2</th>
                        <th>Product Name3</th>
                        <th>Size3</th>
                        <th>Cost3</th>
                        <th>Product Name4</th>
                        <th>Size4</th>
                        <th>Cost4</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {customers.map(customer => (
                        <tr key={customer.id}>
                            <td>{customer.order_id}</td>
                            <td>{customer.name}</td>
                            <td>{customer.email}</td>
                            <td>{customer.mobile}</td>
                            <td>{customer.product_name1}</td>
                            <td>{customer.sizea}</td>
                            <td>{customer.cost1}</td>
                            <td>{customer.product_name2}</td>
                            <td>{customer.sizeb}</td>
                            <td>{customer.cost2}</td>
                            <td>{customer.product_name3}</td>
                            <td>{customer.sizec}</td>
                            <td>{customer.cost3}</td>
                            <td>{customer.product_name4}</td>
                            <td>{customer.sized}</td>
                            <td>{customer.cost4}</td>                            
                            <td>{customer.total_cost}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

        </div>
        </div>
        </div>
        </div>
    );
};

export default CustomerTable;
