import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../Style.css';

const ProductForm = () => {
    const [products, setProducts] = useState([]);
    const [productEntries, setProductEntries] = useState([{ product_id: '', size: '', quantity: 1, cost: 0, sub_total: 0, tax_amt: 0, sizes: [] }]);
    const [customerInfo, setCustomerInfo] = useState({ name: '', email: '', mobile: '', payment_method: 'Cash' });
    const [formErrors, setFormErrors] = useState({});
    const [totalCost, setTotalCost] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [invoiceUrl, setInvoiceUrl] = useState(''); // To store invoice URL
    const [smsResponse, setSmsResponse] = useState(''); // To store SMS response

    useEffect(() => {
        axios.get('https://tjdstore.in/api/get_products.php')
            .then(response => setProducts(response.data))
            .catch(error => console.error('Error fetching products:', error));
    }, []);

    const validateForm = () => {
        let errors = {};
        let isValid = true;

        productEntries.forEach((entry, index) => {
            if (!entry.product_id) {
                errors[`product${index}`] = 'Please select a product';
                isValid = false;
            }
            if (!entry.size) {
                errors[`size${index}`] = 'Please select a size';
                isValid = false;
            }
        });

        if (!customerInfo.name.trim()) {
            errors.name = 'Name is required';
            isValid = false;
        }

        if (!customerInfo.email.trim()) {
            errors.email = 'Email is required';
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(customerInfo.email)) {
            errors.email = 'Invalid email format';
            isValid = false;
        }

        if (!customerInfo.mobile.trim()) {
            errors.mobile = 'Mobile number is required';
            isValid = false;
        } else if (!/^\d{10}$/.test(customerInfo.mobile)) {
            errors.mobile = 'Mobile number must be 10 digits';
            isValid = false;
        }

        setFormErrors(errors);
        return isValid;
    };

    const handleProductChange = (index, event) => {
        const productId = event.target.value;
        const updatedEntries = [...productEntries];
        updatedEntries[index].product_id = productId;

        axios.get(`https://tjdstore.in/api/get_product_details.php?id=${productId}`)
            .then(response => {
                updatedEntries[index].cost = response.data.cost;
                updatedEntries[index].sub_total = response.data.sub_total;
                updatedEntries[index].tax_amt = response.data.tax_amt;
                updatedEntries[index].sizes = response.data.sizes;
                setProductEntries(updatedEntries);
                updateTotalCost(updatedEntries);
            })
            .catch(error => console.error('Error fetching product details:', error));
    };

    const handleSizeChange = (index, event) => {
        const size = event.target.value;
        const updatedEntries = [...productEntries];
        updatedEntries[index].size = size;
        setProductEntries(updatedEntries);
    };

    const handleQuantityChange = (index, event) => {
        const quantity = event.target.value;
        const updatedEntries = [...productEntries];
        updatedEntries[index].quantity = quantity;
        setProductEntries(updatedEntries);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setCustomerInfo(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleAddProduct = () => {
        if (productEntries.length < 4) {
            setProductEntries([...productEntries, { product_id: '', size: '', quantity: 1, cost: 0, sub_total: 0, tax_amt: 0, sizes: [] }]);
        }
    };

    const handleRemoveLastProduct = () => {
        if (productEntries.length > 1) {
            const updatedEntries = productEntries.slice(0, -1);
            setProductEntries(updatedEntries);
            updateTotalCost(updatedEntries);
        }
    };

    const updateTotalCost = (entries) => {
        const total = entries.reduce((sum, entry) => sum + parseFloat(entry.cost || 0), 0);
        setTotalCost(total);
    };

    const sendSms = async (mobile) => {
        try {
            const res = await fetch('https://tjdstore.in/api/send_sms.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    phone_number: mobile,
                }),
            });

            const data = await res.text();
            setSmsResponse(data);
        } catch (error) {
            console.error('Error sending SMS:', error);
            setSmsResponse('Error sending SMS');
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!validateForm()) {
            return;
        }

        const formData = new FormData();
        formData.append('name', customerInfo.name);
        formData.append('email', customerInfo.email);
        formData.append('mobile', customerInfo.mobile);
        formData.append('payment_method', customerInfo.payment_method); // Add payment method here
        formData.append('invoiceUrl', invoiceUrl); // Add invoiceUrl here


        productEntries.forEach((entry, index) => {
            formData.append(`product_id${index + 1}`, entry.product_id);
            formData.append(`size${String.fromCharCode(97 + index)}`, entry.size); // 'a', 'b', 'c'
            formData.append(`quantity${index + 1}`, entry.quantity);
            formData.append(`cost${index + 1}`, entry.cost);
            formData.append(`sub_total${index + 1}`, entry.sub_total);
            formData.append(`tax_amt${index + 1}`, entry.tax_amt);
        });
        formData.append('total_cost', totalCost);
        formData.append('discount', discount); // Add discount here

        axios.post('https://tjdstore.in/api/submit_customer.php', formData)
            .then(response => {
                if (response.data.success) {
                    alert('Customer information saved successfully'); 
                    setInvoiceUrl(response.data.invoiceUrl); // Set invoice URL

                                        // Send the form data to send_mail.php
                                        axios.post('https://tjdstore.in/api/send_mail.php', formData)
                                        .then(mailResponse => {
                                            if (mailResponse.data.success) {
                                                alert('Email sent successfully');
                                            } else {
                                                alert('Failed to send email: ' + mailResponse.data.message);
                                            }
                                        })
                                        .catch(error => console.error('Error sending email:', error));



                    // Send SMS
                    sendSms(customerInfo.mobile);

                    // Clear the form
                    setProductEntries([{ product_id: '', size: '', quantity: 1, cost: 0, sub_total: 0, tax_amt: 0, sizes: [] }]);
                    setCustomerInfo({ name: '', email: '', mobile: '', payment_method: 'Cash' });
                    setDiscount(0); // Reset discount
                    setFormErrors({});
                    setTotalCost(0);
                } else {
                    alert('Failed to save customer information');
                }
            })
            .catch(error => console.error('Error submitting form:', error));
    };

    return (
        <div className="container topic">
            <div className='row justify-content-center'>
                <div className="col-md-10">

                    <div className="card mt-5">
                        <div className="card-body">
                        <h2 className="center">CREATE ORDER</h2>
                            <form onSubmit={handleSubmit}>
                                {productEntries.map((entry, index) => (
                                    <div key={index}>
                                        <label>Product {index + 1}:</label>
                                        <select className="form-select mb-3" onChange={(e) => handleProductChange(index, e)} value={entry.product_id || ''}>
                                            <option value="">Select a product</option>
                                            {products.map(product => (
                                                <option key={product.id} value={product.id}>{product.name}</option>
                                            ))}
                                        </select>
                                        {formErrors[`product${index}`] && <span className="error">{formErrors[`product${index}`]}</span>}

                                        {entry.product_id && (
                                            <div className="mb-3">
                                                <label>Sizes:</label>
                                                {entry.sizes && entry.sizes.map(size => (
                                                    <div className='sizesec' key={size}>
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id={`${size}-${index}`}
                                                            name={`size${index}`}
                                                            value={size}
                                                            onChange={(e) => handleSizeChange(index, e)}
                                                            checked={entry.size === size}
                                                        />
                                                        <label htmlFor={`${size}-${index}`}>{size}</label>
                                                    </div>
                                                ))}
                                                {formErrors[`size${index}`] && <span className="error">{formErrors[`size${index}`]}</span>}
                                            </div>
                                        )}

                                        <div className="mb-3">
                                            <label>Quantity:</label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                min="1"
                                                value={entry.quantity || 1}
                                                onChange={(e) => handleQuantityChange(index, e)}
                                            />
                                        </div>
                                    </div>
                                ))}

                                <div className="mb-3">
                                    <button className="btn btn-primary me-2" type="button" onClick={handleAddProduct} disabled={productEntries.length >= 4}>Add Product</button>
                                    <button className="btn btn-secondary" type="button" onClick={handleRemoveLastProduct} disabled={productEntries.length <= 1}>Remove Last Product</button>
                                </div>

                                <div className="mb-3">
                                    <label>Discount:</label>
                                    <select className="form-select" value={discount} onChange={(e) => setDiscount(e.target.value)}>
                                        <option value="0">0%</option>
                                        <option value="10">10% (Deluxe Ticket)</option>
                                        <option value="15">15% (Super Deluxe Ticket)</option>
                                        <option value="20">20% (Diamond Ticket)</option>
                                    </select>
                                </div>

                                <div className="mb-3">
                                    <label>Customer Name:</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="name"
                                        value={customerInfo.name}
                                        onChange={handleInputChange}
                                    />
                                    {formErrors.name && <span className="error">{formErrors.name}</span>}
                                </div>

                                <div className="mb-3">
                                    <label>Customer Email:</label>
                                    <input
                                        className="form-control"
                                        type="email"
                                        name="email"
                                        value={customerInfo.email}
                                        onChange={handleInputChange}
                                    />
                                    {formErrors.email && <span className="error">{formErrors.email}</span>}
                                </div>
                                <div className="mb-3">
                                    <label>Mobile Number:</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="mobile"
                                        value={customerInfo.mobile}
                                        onChange={handleInputChange}
                                    />
                                    {formErrors.mobile && <span className="error">{formErrors.mobile}</span>}
                                </div>

                                <div className="mb-3">
                                    <label>Payment Method:</label>
                                    <select className="form-select" name="payment_method" value={customerInfo.payment_method} onChange={handleInputChange}>
                                        <option value="Cash">Cash</option>
                                        <option value="Online">Online</option>
                                    </select>
                                </div>

                               

                                <div className="mb-3">
                                    <label>Total Cost:</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        value={totalCost}
                                        disabled
                                    />
                                </div>

                                <div className="mb-3">
                                    <button className="btn btn-success" type="submit">Submit</button>
                                </div>
                            </form>

                            {invoiceUrl && (
        
        <div>
            <iframe src={`https://tjdstore.in/api/${invoiceUrl}`} width="100%" height="600px" title="Invoice" />
            {/* Or you can use an <a> tag for download */}
            {/* <a href={`http://localhost/merch/info/src/Backed/${invoiceUrl}`} target="_blank" rel="noopener noreferrer">Download Invoice</a> */}
        </div>
    )}

                            {smsResponse && (
                                <div className="mt-3">
                                    <p>{smsResponse}</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductForm;
