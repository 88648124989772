import React, { useState, useEffect } from 'react';
import axios from 'axios';

const TicketTable = () => {
    const [Formdata, setFormData] = useState([]);

    useEffect(() => {
        axios.get('https://tjdstore.in/api/get_ticket.php')
            .then(response => setFormData(response.data))
            .catch(error => console.error('Error fetching tickets:', error));
    }, []);

    return (
        <div className="container topic">
            <div className="row">
                <div className="col-md-10">
                    <div className="mt-5">
            <h2 className="center">Ticket Details</h2>
            <table className='table custumt'>
                <thead>
                    <tr>
                        <th>Order ID</th>
                        <th>Ticket category</th>                        
                        <th>Name</th>
                        <th>Email</th>
                        <th>Mobile</th>
                        <th>Amount</th>
                        <th>Payment Method</th>
                        <th>Reference</th>
                    </tr>
                </thead>
                <tbody>
                    {Formdata.map(ticket => (
                        <tr key={ticket.id}>
                            <td>{ticket.order_id}</td>
                            <td>{ticket.ticket_cat}</td>                            
                            <td>{ticket.name}</td>
                            <td>{ticket.email}</td>
                            <td>{ticket.mobile}</td>
                            <td>{ticket.amount}</td>
                            <td>{ticket.payment_method}</td>
                            <td>{ticket.reference}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

        </div>
        </div>
        </div>
        </div>
    );
};

export default TicketTable;
