import React, { useState } from 'react';
import axios from 'axios';
import '../Style.css';

const AddProduct = () => {
    const [product, setProduct] = useState({
        name: '',
        sizes: '',
        cost: '',
        type: ''
    });
    const [message, setMessage] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProduct(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Check for required fields
        if (!product.name || !product.sizes || !product.cost || !product.type) {
            setMessage('All fields are required.');
            return;
        }

        // Send POST request to add the product
        axios.post('https://tjdstore.in/api/add_product.php', product)
            .then(response => {
                if (response.data.success) {
                    setMessage('Product added successfully');
                    setProduct({ name: '', sizes: '', cost: '', type: '' });
                } else {
                    setMessage('Failed to add product: ' + (response.data.error || 'Unknown error'));
                }
            })
            .catch(error => {
                console.error('Error adding product:', error);
                setMessage('Failed to add product');
            });
    };

    return (
        <div className="container topic">
            <div className='row justify-content-center'>
                <div className="col-md-8">
                    <div className="card mt-5">
                        <div className="card-body">
            <h2 className="center">Add New Product</h2>
            <form onSubmit={handleSubmit}>
            <div className="mb-3">
                    <label>Product Name:</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name="name" 
                        value={product.name} 
                        onChange={handleInputChange} 
                    />
                </div>

                <div className="mb-3">
                    <label>Sizes (comma-separated):</label>
                    <input 
                        type="text" 
                        className='form-control'                        
                        name="sizes" 
                        value={product.sizes} 
                        onChange={handleInputChange} 
                    />
                </div>

                <div className="mb-3">
                    <label>Cost:</label>
                    <input 
                        type="number" 
                        className='form-control'                        
                        name="cost" 
                        value={product.cost} 
                        onChange={handleInputChange} 
                    />
                </div>

                <div className="mb-3">
                    <label>Type:</label>
                    <select 
                        name="type" 
                        className='form-select'                        
                        value={product.type} 
                        onChange={handleInputChange}
                    >
                        <option value="">Select a type</option>
                        <option value="tshirt">T-Shirt</option>
                        <option value="book">Book</option>
                        <option value="polotshirt">Polo Shirt</option>
                        <option value="Hoodies">Hoodies</option>
                        <option value="Jackets">Jackets</option>
                        <option value="Mugs">Mugs</option>
                    </select>
                </div>
                <div className="mb-3">
                <button className='btn btn-secondary' type="submit">Add Product</button>
                </div>
            </form>
            {message && <p>{message}</p>}
            </div>
        </div>
        </div>
        </div>
        </div>
    );

};

export default AddProduct;
